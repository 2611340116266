import "./App.module.jsx";
import "aos/dist/aos.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { AppRoutes } from "./utilities/constants.jsx";
import { lazy, Suspense, useEffect } from "react";
import Aos from "aos";
import LazyLoadComp from "./components/LazyLoadComp.jsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const HomePage = lazy(() => import("./pages/Home"));
const CampaignPage = lazy(() => import("./pages/Campaign"));
const BlogPage = lazy(() => import("./pages/blog/Blog.jsx"));
const BlogPostPage = lazy(() => import("./pages/blog/BlogPost.jsx"));

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    Aos.init();
  });
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LazyLoadComp />}>
        <Router>
          <Routes>
            <Route path={AppRoutes.home} element={<HomePage />} />
            <Route path={AppRoutes.campaign} element={<CampaignPage />} />
            <Route path={AppRoutes.blog} element={<BlogPage />} />
            <Route path={AppRoutes.blogPost} element={<BlogPostPage />} />
            <Route
              path="*"
              element={<Navigate to={AppRoutes.home} replace />}
            />
          </Routes>
        </Router>
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
