const AppRoutes = {
  home: "/",
  contactUs: "mailto:contact@fonteinmissions.com",
  blog: "/blog",
  campaign: "/campaign",
  blogPost:"/blog/:postId"
};

const navLinks = [
  { text: "Home", link: AppRoutes.home },
  { text: "Campaign", link: AppRoutes.campaign },
  { text: "Blog", link: AppRoutes.blog },
  { text: "Contact Us", link: AppRoutes.contactUs },
];

export { navLinks, AppRoutes };
