import React from "react";
import styled from "styled-components";

const LazyLoadComp = () => {
  return (
    <Container>
      <img
        src="https://res.cloudinary.com/devbafat/image/upload/v1662561074/logo_ye5jaf.png"
        alt="Brixenmore-Logo"
      />
      <h3>Loading...</h3>
    </Container>
  );
};

export default LazyLoadComp;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;

  img {
    width: 200px !important;
    height: 150px !important;
  }

  h3 {
    font-style: normal;
    font-weight: 300;
    justify-content: center;
    text-transform: capitalize;
    color: green !important;
  }
`;
